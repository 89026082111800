import { Link } from '@remix-run/react'
import clsx from 'clsx'
import { trialText } from '~/config'

export const CallToAction = ({
  children,
  className,
  intent = 'primary',
}: {
  children?: string
  className?: string
  intent?: 'primary' | 'outline'
}) => {
  const { to, cta } = trialText

  return (
    <Link className={clsx('call-to-action', intent, className)} to={to}>
      {children} {cta}
    </Link>
  )
}
